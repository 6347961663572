.back-lucky .table thead th {
  border-bottom: 2px solid #A5651C;
}
.back-lucky .table-bordered td, .back-lucky .table-bordered th {
  border: 1px solid #A5651C !important;
}
.back-lucky .wheel {
  text-align: center;
  padding-top: 50px;
}
.back-lucky .wheel canvas {
  position: relative;
  left: 0;
}
.back-lucky .wheel a#spin_start {
  position: relative;
}
.back-lucky .wheel a#spin_start img {
  width: 90px;
  top: -310px;
  left: -347px;
  position: relative;
  transition: none 0s ease 0s;
  cursor: pointer;
  transform: rotate(3deg);
}

